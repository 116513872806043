<template>
  <div class="miaosha">
    <bread-crumb
      :crumbData="[
        '营销活动',
        '秒杀活动',
        this.$store.state.chooseModule.row ? '编辑' : '新增',
      ]"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>
    <div style="padding: 50px; padding-right: 10%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="活动规则" prop="rule">
          <el-input v-model="ruleForm.rule"></el-input>
        </el-form-item>
        <el-form-item label="活动商品" prop="product">
          <div class="product">
            <div class="product-add" @click="productShow = true">
              <i class="el-icon-plus el-icon"></i>
              <div class="product-add-text">添加商品</div>
            </div>
            <div class="product-delete" @click="deleteProduct">
              <i class="el-icon-delete el-icon"></i>
              <div class="product-delete-text">删除商品</div>
            </div>
          </div>
          <div class="product-text" v-if="ruleForm.product">
            {{ ruleForm.product[ruleForm.product.length - 1].product_name }}
          </div>
        </el-form-item>
        <el-form-item label="活动价" prop="activityPrice">
          <el-input v-model="ruleForm.activityPrice"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="activityStartTime">
          <div class="activi-time">
            <el-date-picker
              v-model="ruleForm.activityStartTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker
            ><span style="margin: 0 10px">-</span>
            <el-date-picker
              v-model="ruleForm.activityEndTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <el-checkbox
              v-model="checked"
              @change="activiChange"
              style="margin-left: 10px"
              >长期活动</el-checkbox
            >
          </div>
        </el-form-item>
        <div
          style="
            display: flex;
            align-items: center;
            padding-left: 10px;
            flex-wrap: nowrap;
          "
        >
          <el-checkbox
            v-model="checkedCycle"
            style="margin-left: 60px"
            @change="cycleChange"
            >开启循环</el-checkbox
          >
          <el-checkbox-group
            v-model="checkedTimes"
            style="margin-left: 10px; font-size: 13px"
            size="mini"
            :border="true"
          >
            <el-checkbox
              v-for="(time, index) in times"
              :label="index"
              :key="time"
              >{{ time }}</el-checkbox
            >
          </el-checkbox-group>
          <div style="display: flex; align-items: center; width: 340px">
            <div style="font-size: 13px; width: 180px; color: #606266">
              循环秒杀时间：
            </div>
            <el-time-picker v-model="time1" size="mini"></el-time-picker
            ><span>-</span
            ><el-time-picker v-model="time2" size="mini"></el-time-picker>
          </div>
        </div>
        <div style="display: flex; margin-top: 15px">
          <el-form-item label="活动库存" prop="StockNum">
            <el-input v-model="ruleForm.StockNum"></el-input>
          </el-form-item>
          <el-form-item label="每人限购" prop="peopleNum">
            <el-input v-model="ruleForm.peopleNum"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="起订量" prop="riseNum">
          <el-input v-model="ruleForm.riseNum" placeholder="0为不限"></el-input>
        </el-form-item>
        <el-checkbox
          v-model="balance"
          style="margin: 0px 0 20px 90px"
          @change="balanceChange"
          >是否可以余额支付</el-checkbox
        >
        <el-form-item label="活动对象" prop="checkedObjs">
          <el-checkbox-group v-model="ruleForm.checkedObjs">
            <el-checkbox
              v-for="(obj, index) in ruleForm.objs"
              :label="index"
              :key="obj"
              >{{ obj }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="ruleForm.sort"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <AddProduct
      :productShow="productShow"
      @addConfirm="addConfirm"
      @handleClose="handleClose"
    />
  </div>
</template>
<script>
import AddProduct from "../../../../addProduct/addProduct.vue";
import { getDate } from "./utilse.js";
import qs from "qs";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        rule: "",
        product: "",
        activityPrice: "",
        activityStartTime: "",
        activityEndTime: "",
        StockNum: "",
        peopleNum: 0,
        riseNum: "",
        checkedObjs: [],
        objs: ["VIP0", "VIP1", "VIP2", "VIP3", "VIP4", "VIP5"],
        sort: "", //排序
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称" }],
        rule: [{ required: true, message: "请输入活动规则" }],
        product: [{ required: true, message: "请添加商品" }],
        activityPrice: [{ required: true, message: "输入活动价格" }],
        activityStartTime: [{ required: true, message: "输入活动开始时间" }],
        StockNum: [{ required: true, message: "请输入活动库存" }],
      },
      productShow: false,
      checked: false, //长期活动
      checkedVal: 0,
      checkedTimes: [],
      times: [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期日",
      ],
      checkedCycle: false, //循环
      cycleVal: 0,
      time1: "", //开始时间
      time2: "", //结束时间
      balance: false, //余额支付
      balanceVal: 0,
      id: 0,
      commercialId: "",
    };
  },
  components: { AddProduct },
  mounted() {
    this.commercialId =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    if (this.$store.state.chooseModule.row) {
      const row = this.$store.state.chooseModule.row;
      this.ruleForm.name = row.activity_title;
      this.ruleForm.rule = row.activity_rule;
      this.id = row.id;
      this.ruleForm.activityPrice = row.activity_price;
      this.ruleForm.peopleNum = row.limited_purchase;
      this.ruleForm.StockNum = row.activity_inventory;
      this.ruleForm.riseNum = row.order_number;
      this.ruleForm.product = row.product_arr;
      this.ruleForm.activityStartTime = new Date(row.activity_start_time);
      this.ruleForm.activityEndTime = new Date(row.activity_end_time);
      row.is_circulate == 0
        ? (this.checkedCycle = false)
        : (this.checkedCycle = true);
      this.time1 = new Date(row.circulate_start_time);
      this.time2 = new Date(row.circulate_end_time);
      row.long_term == 0 ? (this.checked = false) : (this.checked = true);
      row.balance_pay == 0 ? (this.balance = false) : (this.balance = true);
      this.ruleForm.sort = row.sort;
      this.checkedTimes = row.circulate_day;
      this.ruleForm.checkedObjs = row.active_object;
    }
  },
  computed: {},
  methods: {
    // 关闭本页面
    onClose() {
      this.$store.commit("chooseModule/onChoose", {
        chooseModule: "openAc1Module",
      });
      if (this.$store.state.chooseModule.row) {
        this.$store.commit("chooseModule/deleteRow");
      }
    },
    addConfirm(product) {
      this.ruleForm.product = [product[product.length - 1]];
      this.productShow = false;
    },
    deleteProduct() {
      this.ruleForm.product = "";
    },
    handleClose() {
      this.productShow = false;
    },

    activiChange() {
      if (this.checked) {
        this.ruleForm.activityStartTime = new Date();
        this.ruleForm.activityEndTime = new Date(2099, 11, 31, 23, 59, 59);
        this.checkedVal = 1;
      } else {
        this.ruleForm.activityEndTime = "";
        this.checkedVal = 0;
      }
    },

    cycleChange() {
      if (this.checkedCycle) {
        this.cycleVal = 1;
      } else {
        this.cycleVal = 0;
      }
    },
    balanceChange() {
      if (this.balance) {
        this.balanceVal = 1;
      } else {
        this.balanceVal = 0;
      }
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              "/seckill_activity/save",
              qs.stringify({
                id: this.id,
                activity_title: this.ruleForm.name,
                activity_rule: this.ruleForm.rule,
                activity_price: this.ruleForm.activityPrice,
                limited_purchase: this.ruleForm.peopleNum,
                activity_inventory: this.ruleForm.StockNum,
                order_number: this.ruleForm.riseNum,
                product_arr: this.ruleForm.product,
                activity_start_time: getDate(this.ruleForm.activityStartTime),
                activity_end_time: getDate(this.ruleForm.activityEndTime),
                is_circulate: this.cycleVal,
                circulate_day: this.checkedTimes,
                circulate_start_time: getDate(this.time1),
                circulate_end_time: getDate(this.time2),
                active_object: this.ruleForm.checkedObjs,
                long_term: this.checkedVal,
                balance_pay: this.balanceVal,
                sort: this.ruleForm.sort,
                commercial_id: this.commercialId,
              })
            )
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.$store.commit("chooseModule/onChoose", {
                  chooseModule: "openAc1Module",
                });
                if (this.$store.state.chooseModule.row) {
                  this.$store.commit("chooseModule/deleteRow");
                }
              }
            });
        } else {
          this.$message({
            message: "请填入必填项",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.product {
  display: flex;
  &-add,
  &-delete {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-right: 10px;
    cursor: pointer;
    .el-icon {
      font-weight: bolder;
      margin-right: 4px;
    }
  }
  &-text {
    font-size: 13px;
  }
}
.miaosha {
  .el-checkbox {
    margin-right: 10px;
  }
}
</style>
