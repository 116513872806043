/**
 *
 * @param {*} dateStr 需要转换的时间字符串
 * @returns
 */
const getDate = (dateStr) => {
  if (!dateStr) return;
  const date = new Date(dateStr);
  // 使用Date对象的方法获取年、月、日、时、分、秒
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
  let day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);

  // 按照所需的格式拼接字符串
  let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
/**
 *
 * @param {*} dateStr 需要转换的时间字符串
 * @returns
 */
const getTime = (dateStr) => {
  if (!dateStr) return;
  const date = new Date(dateStr);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);
  const time = `${hours}:${minutes}:${seconds}`;
  return time;
};
export { getDate, getTime };
